import form from "@/data/fr/from.js";

export default {
    "form": form,
    "yes": "Oui",
    "no": "Non",
    "new_phone": "Nouveau téléphone",
    "not_selected": "Pas sélectionné",
    "plan_title": "Nouveau Forfait Mobile",
    "plan_type_label": "Type de régime",
    "select_province_label": "Sélectionnez la province",
    "province": "Province",
    "tax_rate": "Taux d'imposition",
    "monthly": "Mensuel",
    "monthly_rate": "Tarif mensuel",
    "phone": "Téléphoner",
    "months": "Mois",
    "roger_label": "Êtes-vous un client de Rogers?",
    "data_plan_label": "Sélectionnez un forfait de données",
    "select_phone_label": "Sélectionnez le téléphone",
    "select_phone_color_label": "Choisissez la couleur",
    "save_as_pdf": "Enregistrer au format PDF",
    "data_plan": "Forfait de données*",
    "list_price": "Prix de vente au détail du téléphone",
    "tax": "Impôt",
    "tax_small": "impôt",
    "for": "pour",
    "discount_applied": "remise appliquée",
    "total": "Totale",
    "color": "Couleur",
    "you_pay_upfornt": "Vous payez pour",
    "you_save": "Vous économisez",
    "you_save_monthly": "Vous économisez mensuellement",
    "you_save_upfornt": "Vous économisez pour",
    "invoice_credit": "Facture de crédit",
    "setup_fee": "Frais d'installation",
    "monthly_cost": "Coût mensuel",
    "monthly_discount": "Remise mensuelle",
    "effective_rate": "Taux effectif",
    "effective_plan_rate_after_tax": "Taux effectif du forfait après impôt",
    "data_plan_has": "*Le forfait de données a",
    "manufacturer_link": "Fabricant",

    //more form
    "form_complete_msg": "Veuillez compléter le formulaire ci-dessous pour recevoir des informations supplémentaires.",
    "app_note": "Qui est votre fournisseur de forfait mobile actuel? Si votre fournisseur n'est pas listé, sélectionnez",
    "additional_form_plan_provider": "Qui est votre fournisseur de forfait mobile actuel?",
    "additional_form_plan_provider_note": "Il s'agit du numéro de téléphone transféré. Si plus d'un numéro de téléphone sous le même compte est transféré, veuillez entrer les numéros secondaires ci-dessous dans les informations supplémentaires.",
    "email": "Courriel",
    "phone_number": "Numéro de téléphone",
    "plan_interested": "Combien de plans souhaitez-vous?",
    "roger_questions": "Êtes-vous actuellement un client de Rogers?",
    "new_device": "Nouveau appareil demandé",
    "new_device_help": "Les téléphones avec $0 dans le nom de la liste des téléphones sont inclus dans le forfait mensuel sans frais supplémentaires.",
    "plan_compare": "Comparaison des plans",
    "plan_compare_help": "Si vous souhaitez que nous effectuions une comparaison de plans, plus téléchargez une facture récente. Cela n'est pas requis.",
    "comments": "Commentaires",
    "additional_information": "Toutes les informations supplémentaires pour nous ou vos questions?",
    "submit": "Soumettre",

    //signup
    "interaction_id_label": "Identifiant d'interaction",
    "interaction_id_help": "Comment obtenir votre ID d'interaction",
    "rogers_instructions": "Les clients de Rogers doivent avoir un numéro d'identification d'interaction pour pouvoir profiter de cette offre",
    //Sign Up Faq:
    "signup_faq": "Foire aux questions sur l'inscription :",
    //Rogers Step 1
   "rogers_note_1": "NOTE: Lorsqu'il y a plus d'une ligne dans le compte et que vous ne transférez pas toutes les lignes, veuillez demander à Rogers Business Support de faire de l'une des lignes restantes la ligne principale du compte. De même, s'il y a un plan de partage de données avec des lignes dans le compte et que vous ne transférez pas toutes les lignes, veuillez demander à Rogers Business Support de supprimer le partage de données de la ligne transférée. Sinon, la demande de transfert sera refusée jusqu'à ce que ces éléments aient été autorisés.",
    "rogers_note_2": "Vous continuez à avoir le contrôle total de votre numéro de téléphone et pouvez demander à ce qu'il soit transféré ou libéré à tout moment en contactant Sydian au 416 238 1444 ou  weet@sydiangroup.com.",
    "step_1_details": "Pour rejoindre le plan SAVE, vous devez déplacer votre numéro de téléphone du côté consommateur vers le compte d'entreprise du responsable du groupe d'achat Sydian Group. Veuillez appeler le soutien aux entreprises de Rogers <a href='tel:18772743375' style='text-decoration:underline;color:#da291c;' target='_blank'>1-877-274-3375</a> et dites <b>Veuillez placer une note sur ma ligne indiquant que Sydian Group reprend la propriété de toutes les lignes de mon compte</b>. Rogers fournit un numéro de confirmation pendant que vous êtes au téléphone. Veuillez vous assurer qu'il s'agit d'un ID d'interaction et qu'il commence par un I.<br><br>REMARQUE : Lorsqu'il y a plus d'une ligne dans le compte et que vous ne transférez pas toutes les lignes, veuillez demander au Soutien aux entreprises de Rogers de créer l'une des lignes restantes la ligne principale du compte. De même, s'il existe un forfait de partage de données avec des lignes dans le compte et que vous ne transférez pas toutes les lignes, veuillez demander au Soutien aux entreprises de Rogers de supprimer le partage de données de la ligne en cours de transfert. Sinon, la demande de transfert sera refusée jusqu'à ce que ces éléments aient été autorisés.<br><br>Vous continuez à avoir un contrôle total sur votre numéro de téléphone et pouvez demander qu'il soit transféré ou libéré à tout moment en contactant Sydian.<br>< br> En cas de problème, veuillez contacter le responsable du groupe d'achat Sydian Group au <a href='tel:4162381444'>416 238 1444</a> ou <a href='mailto:sweet@sydiangroup.com'>sweet@ sydiangroup.com</a>",
    "interaction_part_1": "Pour rejoindre le plan SAVE, vous devez déplacer votre numéro de téléphone du côté consommateur vers le compte d'entreprise du responsable du groupe d'achat Sydian Group. Veuillez appeler le soutien aux entreprises de Rogers au 1-877-274-3375 et dire Veuillez placer une note sur ma ligne indiquant que Sydian Group prend en charge toutes les lignes de mon compte. Rogers fournit un numéro de confirmation pendant que vous êtes au téléphone. Veuillez vous assurer qu'il s'agit d'un ID d'interaction et qu'il commence par un I",
    "interaction_part_2": "REMARQUE : Lorsqu'il y a plus d'une ligne dans le compte et que vous ne transférez pas toutes les lignes, veuillez demander au Soutien aux entreprises de Rogers de créer l'une des lignes restantes la ligne principale du compte. De même, s'il existe un forfait de partage de données avec des lignes dans le compte et que vous ne transférez pas toutes les lignes, veuillez demander au Soutien aux entreprises de Rogers de supprimer le partage de données de la ligne en cours de transfert. Sinon, la demande de transfert sera refusée jusqu'à ce que ces éléments aient été autorisés.",
    "interaction_part_3": "Vous continuez à avoir un contrôle total sur votre numéro de téléphone et pouvez demander qu'il soit transféré ou libéré à tout moment en contactant Sydian.",
    "interaction_part_4": "En cas de problème, veuillez contacter le responsable du groupe d'achat Sydian Group au" +
        " 416 238 1444 ou sweet@sydiangroup.com.",
    "copy": "Copie",
    "signup_note": "Votre fournisseur actuel nous demande de soumettre votre numéro de compte avant de transférer le compte à Rogers.",
    "current_mobile_phone_number": "Numéro de téléphone en cours de transfert.",
    "current_mobile_phone_number_note": "Il s'agit du numéro de téléphone en cours de transfert. Si plusieurs numéros de téléphone sous le même compte sont transférés, veuillez saisir les numéros secondaires ci-dessous dans Informations supplémentaires.",
    "new_plan_selected": "Nouveau plan sélectionné",
    "select_plan": "Sélectionnez un plan",
    "line_1_plan_selected": "Ligne 1 - Plan sélectionné",
    "plan_cost": "Coût du plan",
    "phone_upfront_cost": "Coût initial du téléphone",
    "security_deposit": "Dépôt de sécurité",
    "phone_to_purchase": "Numéro de téléphone à acheter",
    "plan_and_phone": "Informations sur le plan et le téléphone",
    "monthly_invoices_msg": "Les factures mensuelles seront envoyées à cette adresse e-mail.",
    //Contact Phone
    "contact_phone": "Numéro du contact",
    "contact_phone_note": "Si différent du numéro de téléphone portable actuel ci-dessus.",
    //Shipping address
    "shipping_address": "Adresse de livraison",
    "shipping_address_note": "Si différent de l'adresse de facturation.",
    //SIM card and\\or new phones are shipped to this address. Rogers does not ship to PO" +
    //         " Boxes. A signature is required for delivery
    "shipping_address_note2": "Les cartes SIM et/ou les nouveaux téléphones sont expédiés à cette adresse. Rogers ne livre pas aux boîtes postales. Une signature est requise pour la livraison.",
    //City
    "city": "Ville",
    //Postal Code
    "postal_code": "Code postal",
    "country": "Pays",
    //country_note
    "country_note": " VEUILLEZ NOTER: La carte SIM et/ou les nouveaux téléphones sont expédiés à cette adresse. Une" +
        " signature est requise pour la livraison.",
    //Billing To: Name
    "billing_to_name": "Nom de la personne à facturer",
    "billing_to_name_note": "Si différent de l'adresse de facturation.",
    //Additional Information
    "signup_additional_information": "Informations supplémentaires",
    "signup_additional_information_note": "Ajoutez toute autre information ou numéro de téléphone supplémentaire pouvant être transféré.",
    //Street Address
    "street_address": "Adresse de rue",
    "summary": "Sommaire",

    //Address Line 2
    "address_line_2": "Adresse Ligne 2",
    'no_plan_selected': 'Aucun plan sélectionné',
    "name": "Nom",
    "first_name": "Prénom",
    "last_name": "Nom de famille",
    "current_provider": "Fournisseur actuel",
    "billing_name": "Nom de la personne à facturer",
    "additional_lines": "AJOUTER DES LIGNES SUPPLÉMENTAIRES",
    "other_information_comment": "Commentaires? Toutes les autres informations que nous devrions savoir?",
    "disclaimers": "Avertissements",
    "add_first_line": "Ajouter le premier numéro de téléphone à transférer - Des lignes supplémentaires peuvent être ajoutées en cliquant sur Ajouter des lignes supplémentaires ci-dessous",
    "add_first_line1": "Ajouter le premier numéro de téléphone à transférer",
    "add_first_line2": "Des lignes supplémentaires peuvent être ajoutées en cliquant sur Ajouter des lignes supplémentaires ci-dessous",
    "current_account_number": "Ligne 1 - Numéro de compte actuel",
    //Please input the other providers name
    "other_provider_name": "Nom du fournisseur",
    //Selected Provider
    "selected_provider": "Fournisseur sélectionné",
    //Includes Tax
    "includes_tax": "Comprend les taxes",
    //for the phone
    "for_the_phone": "pour le téléphone",
    //on your phone purchase
    "on_your_phone_purchase": "sur votre achat de téléphone",
    //No Phone selected
    "no_phone_selected": "Aucun téléphone sélectionné",
    "monthly_plus_tax": "mensuel + Taxe",
    "required_by_your_provider": "Exigé par votre fournisseur avant que nous puissions transférer",
    "select_language": "Sélectionner une langue",
    "select_phone": "Sélectionner un téléphone",
    "select_color": "Sélectionner la couleur",
    "line": "Ligne",
    "phone_selected": "Téléphone sélectionné",
    "select_phone_color": "Sélectionnez la couleur du téléphone",
    "byod": "AVPA",
    "choose_file": "Choisissez Fichier",
    "selected_file": "Fichier sélectionné",
    //Less than 5
    "less_than_5": "Moins de 5",
    //5 or more
    "5_or_more": "5 ou plus",
    "account_number_error": "Le numéro de compte et le numéro de téléphone ne peuvent pas être identiques. Veuillez trouver votre numéro de compte sur votre facture et entrer un nouveau numéro. Votre fournisseur nous demande de fournir le numéro de compte lors du transfert de votre numéro de téléphone.",

    'all_customer_title': 'Tous les clients',
    'rogue_customer_title': 'Clients Rogers uniquement',
    'all_customer_1': '1. Vous devez avoir le numéro de compte et votre numéro de téléphone de votre fournisseur' +
        ' actuel. Votre numéro de compte est différent de votre numéro de compte et votre fournisseur actuel nous demande de soumettre ce numéro à eux avant de transférer votre numéro.',
    'all_customer_2': '2. Votre adresse de livraison ne peut pas être une boîte postale. Rogers exige une signature' +
        ' pour la livraison. Si vous avez votre carte SIM / téléphone expédié à une boîte postale, il sera retourné.',
    'rogers_customer_1': '1. Rogers exige que les clients Rogers obtiennent un ID d\'interaction pour profiter de' +
        ' cette offre. Vous devez obtenir l\'ID pour terminer le processus d\'inscription.',
    'next': 'Suivant',
    'previous': 'Précédente',
    'what_is_it_going_to_cost': 'Qu\'est-ce que ça va coûter ?',
    'complete_the_form': 'Remplissez le formulaire ci-dessous pour vous inscrire',
    'sign_up': 'Inscrivez-vous',
    'before_signing_up': 'Avant de vous inscrire',
    'cost_calculator': 'Calculateur de coûts',
    'ONLY_VOICE': 'Voix et SMS uniquement',
    '10GB': '10Go',
    '20GB': '20Go',
    '25GB': '25Go',
    '50GB': '50Go',
    '100GB': '100Go',
}
