<template>
  <div class="row">
    <Logo/>
    <div class="row">
      <div class="col-lg-12">

        <div class="signupSec">
          <div class="siginuphead">
            <h3>{{$t('cost_calculator')}}</h3>
          </div>
          <div class="formsec">
            <div class="formRow">
              <label> {{ $t('roger_label') }}</label>
              <select v-model="form.rogerCustomers">
                <option selected>{{ $t('yes') }}</option>
                <option>{{ $t('no') }}</option>
              </select>
            </div>
            <div class="formRow">
              <label>{{ $t("plan_type_label") }}</label>
              <select v-model="form.planType">
                <option>{{ $t('byod') }}</option>
                <option selected>{{ $t('new_phone') }}</option>
              </select>
            </div>
            <div class="formRow">
              <label>{{ $t("select_province_label") }}</label>
              <select v-model="form.selectedProvince">
                <option value="">{{ $t('select_province_label') }}</option>
                <option v-for="(province,index) in form.provinces" :key="index" :value="index+1">{{
                    province.name
                  }}
                </option>
              </select>
            </div>
            <div class="formRow">
              <label>{{ $t('data_plan_label') }}</label>
              <select id="selected_plan" required v-model="form.selectedPlan">
                <option selected value="">{{ $t('select_plan') }}</option>
                <option v-for="(plan,index) in form.plans" :key="index" :value="index+1">{{ plan.data }}</option>
              </select>
            </div>

            <div v-show="form.planType===$t('new_phone')">
              <div class="formRow">
                <label>
                  {{ $t('select_phone') }}
                </label>
                <select id="selected_phone" required v-model="form.selectedPhone">
                  <option selected value="">None</option>
                  <option v-for="(phone, index) in form.phones" :key="index" :value="index+1">
                    {{ phone.phone }}
                  </option>
                </select>
                <small class="formRow">{{ $t('new_device_help') }}</small>
              </div>
               <div class="formRow"></div>

              <div v-if="form.selectedPhone" class="formRow">
                <label>
                  {{ $t('select_color') }}
                </label>
                <select required id="phone_color" v-model="form.selectedColor">
                  <option selected value="">{{ $t('select_phone_color') }}</option>
                  <option v-for="(color, index) in form.phones[form.selectedPhone-1].colours" :key="index">
                    {{ color }}
                  </option>
                </select>

              </div>
            </div>
            <div v-for="(data, key) in form.newDesignData" :key="key" style="width: 90%">
              <ShowData v-if="data.type === 'single'" :data="data"></ShowData>
              <div v-else-if="data.type === 'group' && !data.hide" class="card p-2" style="margin-bottom: 15px">
                <ShowData v-for="(data, key) in data.data" :key="key" :data="data"></ShowData>
              </div>
              <div v-else-if="data.type === 'disclaimer_group' && !hideDisclaimer">
                <div v-if="form.additionalInfo">
                  <b class="card-title">{{ $t("plan_and_phone") }}</b>
                  <div v-html="form.additionalInfo"></div>
                </div>
                <ShowData v-for="(data, key) in data.data" :key="key" :data="data"></ShowData>
              </div>
            </div>

            <div class="textCol">
              <div class="next-btn" style="cursor: pointer" @click="validate()">{{$t('next')}}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <InterActionModal v-if="showInteractionModal" @close="showInteractionModal = false"></InterActionModal>
  </div>
</template>

<script>
import Form from "@/components/logics/Form";
import InterActionModal from "@/components/InterActionModal.vue";
import Logo from "@/components/Logo.vue";
import ShowData from "@/components/ShowData.vue";

export default {
  extends: Form,
  components: {
    Logo,
    InterActionModal,
    ShowData
  },
  data() {
    return {
      showInteractionModal: false
    };
  },
  methods: {
    validate(){
      //plan is required
      if(!this.form.selectedPlan){
        document.getElementById("selected_plan").reportValidity();
        return false;
      }

      //if new phone is selected, phone and color are required
      if(this.form.planType === this.$t('new_phone')){
        if(!this.form.selectedPhone){
          document.getElementById("selected_phone").reportValidity();
          return false;
        }
        if(!this.form.selectedColor){
          document.getElementById("phone_color").reportValidity();
          return false;
        }
      }
      this.nextStep();

    },
    nextStep() {
      this.$emit('nextStep');
    },
    previousStep() {
      this.$emit('previousStep');
    },
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select, option {
  font-size: 0.8rem;
}
</style>
