<template>
  <div class="row">
    <div class="container">
      <Logo/>
      <div class="row">
        <div class="col-lg-12">

          <div class="signupSec">
            <div class="siginuphead">
              <h3>{{$t('before_signing_up')}}</h3>
            </div>
            <div class="formsec">
              <div class="formRow">
                <label> {{ $t('roger_label') }}</label>
                <select v-model="form.rogerCustomers">
                  <option selected>{{ $t('yes') }}</option>
                  <option>{{ $t('no') }}</option>
                </select>
              </div>
              <div class="formRow" v-if="form.rogerCustomers === $t('yes')">
                <label>{{ $t('rogue_customer_title') }}</label>
                <ul>
                  <li>{{ $t('rogers_customer_1') }}</li>
                   <a href="" @click.prevent="showInteractionModal = true">{{ $t('interaction_id_help') }}</a>
                </ul>
              </div>
              <div class="formRow">
                <label>{{$t('all_customer_title')}}</label>
                <ul>
                  <li>{{$t('all_customer_1')}}</li>
                  <li>{{$t('all_customer_2')}}</li>
                </ul>
              </div>

              <div class="textCol">
                <div class="popupbtn" style="cursor: pointer" @click="nextStep">{{$t('next')}}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <p class="zf-frmDesc"></p>
    <InterActionModal v-if="showInteractionModal" @close="showInteractionModal = false"></InterActionModal>

  </div>
</template>

<script>


import InterActionModal from "@/components/InterActionModal.vue";
import Logo from "@/components/Logo.vue";

export default {
  name: 'BeforeSignUpForm',
  components: {Logo, InterActionModal},
  data() {
    return {
      showInteractionModal : false,
    };
  },
  props: {
    form: Object
  },
  methods: {
    nextStep() {
      this.$emit('nextStep');
    }
  }
}
</script>
