<template>
  <div>
    <template v-if="data.hide">

    </template>
    <template v-else>
      <div class="formRow" v-if="data.add_space"></div>
      <div v-if="!Array.isArray(data.value)" class="formRow row" style="margin:0 0 0px;">
        <div class="col"><label>{{ data.title }}</label></div>
        <div class="col">
          <div v-if="isUrl(data.value)">
            <a class="text-break" :href="data.value" target="_blank">{{ data.value }}</a>
          </div>
          <div v-else-if="data.right_justify" style="text-align: right;width: 120px">
            {{ data.value }}
            <div v-if="data.show_bottom_border" class="border-bottom border-primary" style="width: 120px;"></div>
          </div>
          <div v-else>
            {{ data.value }}
            <div v-if="data.show_bottom_border" class="border-bottom border-primary" style="width: 120px;"></div>
          </div>
        </div>
      </div>
      <div v-else class="formRow row">
        <label>{{ data.title }}</label>
        <ul>
          <li v-for="(message, index) in data.value" :key="index">{{ message }}</li>
        </ul>
      </div>
       <div class="formRow" v-if="data.add_space"></div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'Form',
  data() {
    return {

    };
  },
  methods:{
    isUrl(value){
      value = value.toString();
      return value.indexOf('http') > -1;
    },

  },
  props: {
    data: Object
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
