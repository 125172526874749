<template>
  <div>

    <div class="row">
      <div class="col">
        <label>Line {{ idx }}</label>
      </div>
      <div class="col"></div>
      <div class="col">
        <button class="btn btn-danger btn-sm" @click.prevent="removeLine(idx)">Remove</button>
      </div>


    </div>
    <div class="formRow">
      <label>{{$t('name')}} <span :style="asterisksColor"> *</span> </label>
      <input required="required" type="text" v-model="form.name" maxlength="255" fieldType=1/>

      <div class="formRow">
        <label>{{$t("current_provider")}} <span :style="asterisksColor"> *</span> </label>
        <select required="required" class="zf-form-sBox" checktype="c1"
                @change="onProviderChange($event)">
          <option v-if="!isRogersCustomer()" selected="true" value="">-Select-</option>
          <option v-for="(provider,idx) in providers" :value="provider" :key="idx">{{ provider }}</option>
        </select>
      </div>


      <small class="note">
        {{$t("additional_form_plan_provider")}}
      </small>

      <div class="formRow">
          <label>{{$t("current_mobile_phone_number")}} <span :style="asterisksColor"> *</span> </label>
          <PhoneNumber :currentMobilePhoneNumber="form.currentMobilePhoneNumber"
                       :currentProvider="form.currentProvider"
                       @update:currentMobilePhoneNumber="form.currentMobilePhoneNumber = $event"/>
        <small class="note">{{$t('additional_form_plan_provider_note')}}</small>
      </div>

      <div class="formRow">
        <label >
          {{ $t("plan_type_label") }} {{ idx }}
        </label>
        <select v-model="form.planType" :name="'SingleLine12_'+idx">
          <option>{{ $t('byod') }}</option>
          <option selected>{{ $t('new_phone') }}</option>
        </select>
      </div>
      <div class="formRow">
        <label>
          {{ $t('data_plan_label') }}
        </label>
        <select id="selected_plan" required class="form-select form-select-sm" v-model="form.selectedPlan">
          <option selected value="">{{ $t('select_plan') }}</option>
          <option v-for="(plan,index) in form.plans" :key="index" :value="index+1">{{ plan.data }}</option>
        </select>
      </div>

      <div class="formRow">
          <label>{{$t('new_plan_selected')}} <span :style="asterisksColor"> *</span> </label>
          <input type="hidden" :name="'SingleLine10_'+idx" checktype="c1" :value="planDescription">
          {{ planDescription }}
      </div>

      <div v-if="form.planType===this.$t('new_phone')">
        <div class="formRow">
          <label class="form-check-label">
            {{ $t('select_phone') }}
          </label>
          <select id="selected_phone" required
                  v-model="form.selectedPhone">
            <option selected value="">None</option>
            <option v-for="(phone, index) in form.phones" :key="index" :value="index+1">
              {{ phone.phone }}
            </option>
          </select>
        </div>

        <div v-if="form.selectedPhone" class="formRow">
          <label>
            Phone Colour
          </label>
          <select required id="phone_color" v-model="form.selectedColor">
            <option selected value="">{{$t('select_phone_color')}}</option>
            <option v-for="(color, index) in form.phones[form.selectedPhone-1].colours" :key="index">
              {{ color }}
            </option>
          </select>

        </div>
      </div>


    </div>

    <div class="formRow">
      <div v-if="this.discount < 0"><small>{{ $t('plan_cost') }} - <i>{{ preTaxRateText }} + tax(discount applied - {{
          this.discountText
        }})</i></small></div>
      <div v-else><small>{{$t('plan_cost')}} - <i>{{ monthlyRateText }} + {{$t('tax')}} </i></small></div>
      <div><small>{{$t('phone_upfront_cost')}}- <i>{{this.upFrontWithoutTaxText}}</i></small></div>
    <div><small v-if="form.securityDeposit">{{$t('security_deposit')}} - <i> ${{form.securityDeposit}}</i></small></div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/logics/Form";
import PhoneNumber from "@/components/Fields/PhoneNumber.vue";

export default {
  name: 'AdditionalForm',
  components: {PhoneNumber},
  extends: Form,
  methods: {
    updateOtherProvider(event) {
      if (event.target.value.toLowerCase() === 'rogers') {
        this.form.rogerCustomers = this.$t('yes');
        return;
      }
      this.form.currentProviderValue = 'Other - ' + event.target.value;
    },
    onProviderChange(event) {
      this.form.currentProvider = event.target.value;
      if (this.form.currentProvider === 'None') {
        this.form.currentAccountNumber = '000000000';
        this.form.currentMobilePhoneNumber = '000-000-000';
      } else if (this.form.currentAccountNumber === '000000000') {
        this.form.currentAccountNumber = '';
        this.form.currentMobilePhoneNumber = '';
      }

      this.form.currentProviderValue = this.form.currentProvider;

    },
    removeLine(index) {
      //send to parent
      this.$emit('remove', index-2)
    },
  },
  props: {
    idx: Number,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select, option {
  font-size: 0.8rem;
}
</style>
