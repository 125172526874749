<template>
  <ModalContent :title="$t('what_is_it_going_to_cost')">
    <Form :form="form" v-if="currentStep===1" @nextStep="currentStep++"/>
    <MoreForm :form="form" v-if="currentStep===2" @previousStep="--currentStep"/>
  </ModalContent>

</template>

<script>
import Form from './components/MoreForm/Form.vue'
import MoreForm from "@/components/MoreForm";
import ModalContent from "@/components/ModalContent.vue";

export default {
  name: 'MoreApp',
  components: {
    ModalContent,
    Form, MoreForm
  },
  data() {
    return {
      currentStep: 1,
    };
  },
  props: {
    form: Object
  },

}
</script>

<style>
#app {

}
</style>
