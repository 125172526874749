<template>
  <div class="">

    <div>
      <div>
        <div class="formRow">
          <p>{{$t('add_first_line1')}} <small>{{$t('add_first_line2')}}</small></p>
          <label>{{$t('line')}} 1 - {{$t("current_provider")}} <span :style="asterisksColor"> *</span> </label>
          <select required="required" checktype="c1"
                  @change="onProviderChange($event)">
            <option v-if="!isRogersCustomer()" selected="true" value="">-Select-</option>
            <option v-for="(provider,idx) in providers" :value="provider" :key="idx">{{ provider }}</option>
          </select>
          <input type="hidden" name="SingleLine11" :value="form.currentProviderValue">
        </div>

        <div v-if="form.currentProvider==='Other'">
          <div class="formRow">
            <label>{{$t('other_provider_name')}} <span :style="asterisksColor"> *</span> </label>
            <input type="text" value="" required @keyup="updateOtherProvider"
                   fieldType=1 class="form-control">
          </div>

          <div class="formRow">
            <label>{{$t('selected_provider')}} <span :style="asterisksColor"> *</span> </label>
            <i>{{ form.currentProviderValue }}</i>
          </div>
        </div>

        <div class="formRow">
          <p class="reduceMargin">
            <label>
              {{$t("current_account_number")}} <span :style="asterisksColor"> *</span>
            </label>
            <input type="text" required="required" name="SingleLine2" checktype="c1"
                   v-model="form.currentAccountNumber"
                   :readonly="form.currentProvider === 'None'"
                   maxlength="255" fieldType=1/>
          <p class="error" v-if="accountNumberError"><small>{{ $t('account_number_error') }}</small></p>
          <p><small>{{ $t('required_by_your_provider') }}</small></p>
        </div>


        <!---------Single Line Starts Here---------->
        <div v-if="isRogersCustomer()" class="formRow">
          <label>{{$t('line')}} 1 - {{$t("interaction_id_label")}}<span :style="asterisksColor"> *</span>
          </label>
          <input v-model="form.integrationId" required type="text" name="SingleLine6" checktype="c1"
                 value=""
                 maxlength="255"
                 fieldType=1/>
        </div>

        <div v-if="isRogersCustomer()" class="formRow">
          <a href="#" @click.prevent="showInteractionModal = true">{{$t('interaction_id_help')}}</a>
        </div>

        <!---------Single Line Ends Here---------->


      </div>

      <div class="formRow">
        <label>{{ $t('line') }} 1 - {{ $t("current_mobile_phone_number") }}
          <span :style="asterisksColor"> *</span>
        </label>
        <input type="hidden" name="SingleLine3" :value="form.currentMobilePhoneNumber">
        <PhoneNumber :currentMobilePhoneNumber="form.currentMobilePhoneNumber"
                     :currentProvider="form.currentProvider"
                     @update:currentMobilePhoneNumber="form.currentMobilePhoneNumber = $event"/>
      </div>

      <div v-html="lineSummaryHtml"></div>

      <input type="hidden" name="SingleLine12" :value="form.planType" fieldType=1 maxlength="255"/>
      <input type="hidden" name="SingleLine10" checktype="c1" :value="planDescription">
      <input type="hidden" name="SingleLine7" checktype="c1" :value="phoneInfo">
    </div>
    <InterActionModal v-if="showInteractionModal" @close="showInteractionModal = false"></InterActionModal>
  </div>
</template>

<script>
import Form from "@/components/logics/Form";
import PhoneNumber from "@/components/Fields/PhoneNumber.vue";
import InterActionModal from "@/components/InterActionModal.vue";

export default {
  name: 'PrimaryForm',
  components: {InterActionModal, PhoneNumber},
  data() {
    return {
      showInteractionModal: false
    };

  },
  extends: Form,
  methods: {
    showAccountNumberError() {
      let accountNumberField = document.getElementsByName('SingleLine2')[0];
      accountNumberField.focus();
      this.form.currentAccountNumber = '';
    },
    async copyText(id) {
      //get text from html id
      const text = document.getElementById(id).innerText;

      try {
        await navigator.clipboard.writeText(text);
        alert('Instruction copied to clipboard');
      } catch ($e) {
        alert('Cannot copy');
      }
    },
    updateOtherProvider(event) {
      if (event.target.value.toLowerCase() === 'rogers') {
        this.form.rogerCustomers = this.$t('yes');
        return;
      }
      this.form.currentProviderValue = 'Other - ' + event.target.value;
    },
    onProviderChange(event) {
      this.form.currentProvider = event.target.value;
      if (this.form.currentProvider === 'None') {
        this.form.currentAccountNumber = '000000000';
        this.form.currentMobilePhoneNumber = '000-000-000';
      } else if (this.form.currentAccountNumber === '000000000') {
        this.form.currentAccountNumber = '';
        this.form.currentMobilePhoneNumber = '';
      }

      this.form.currentProviderValue = this.form.currentProvider;

    },
  },
  computed: {
    accountNumberError() {
      let error = this.form.currentAccountNumber && this.form.currentAccountNumber ===
          this.form.currentMobilePhoneNumber;
      if (error) {
        //focus on account number
        this.showAccountNumberError()
      }
      return error;
    },
  },
  props: {
    idx: Number,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select, option {
  font-size: 0.8rem;
}
</style>
