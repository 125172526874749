export default {
  data() {
    return {
      locales: ["fr", "en"],
      'printId': 'print-form-' + Math.floor(Math.random() * 10),
      phoneDisc: 0,
      'hideDisclaimer': true,
      phoneUpfront: 0,
    };
  },
  created() {
    this.generateData();
  },
  methods: {
    resize(ref) {
      setTimeout(() => {
        this.$refs[ref].style.height = 'auto';
        this.$refs[ref].style.height = this.$refs[ref].scrollHeight + 'px';
      }, 200);
    },
    generateData() {
      this.form.disclaimer = {
        "id": "27,30",
        "type": "disclaimer_group",
        "hide": this.hideDisclaimer,
        "data": [
          {
            "id": 27,
            "type": "single",
            "title": this.$t('disclaimers'),
            "value": this.form.disclaimers,
            "hide": false,
            "show_bottom_border": false,
          }, {
            "id": 28,
            "type": "single",
            "title": this.$t('signup_additional_information'),
            "value": this.form.messages,
            "hide": true,
            "show_bottom_border": false,
          }, {
            "id": 29,
            "type": "single",
            "title": "",
            "value": (new Date().toLocaleDateString()),
            "hide": false,
            "show_bottom_border": false,
          }
        ]
      };
      //create json data
      this.form.pdfData = [
        {
          "id": 1,
          "type": "single",
          "title": this.$t('roger_label'),
          "value": this.form.rogerCustomers,
          "hide": false,
          "show_bottom_border": false,
        }, {
          "id": 2,
          "type": "single",
          "title": this.$t('plan_type_label'),
          "value": this.form.planType,
          "hide": false,
          "show_bottom_border": false,
        }, {
          "id": 3,
          "type": "single",
          "title": this.$t('province'),
          "value": this.provinceName,
          "hide": false,
          "show_bottom_border": false,
        }, {
          "id": 4,
          "type": "single",
          "title": this.$t('tax_rate'),
          "value": this.provinceTaxText,
          "hide": false,
          "show_bottom_border": false,
        }, {
          "id": 7,
          "type": "single",
          "title": this.$t('monthly_rate'),
          "value": this.monthlyRateText,
          "hide": !this.form.selectedPlan || this.discount >= 0,
          "show_bottom_border": false,
        }, {
          "id": 9,
          "type": "single",
          "title": this.$t('phone'),
          "value": this.phoneName,
          "hide": this.hidePhoneInfo,
          "show_bottom_border": false,
        }, {
          "id": 5,
          "type": "single",
          "title": this.$t('months'),
          "value": this.form.months,
          "hide": this.hidePhoneInfo,
          "show_bottom_border": false,
        }, {
          "id": 6,
          "type": "single",
          "title": this.$t('data_plan') + "*",
          "value": this.dataPlan,
          "hide": false,
          "show_bottom_border": false,
        }, {
          "type": "single",
          "title": this.$t('monthly_rate'),
          "value": this.monthlyRateText,
          "hide": false,
          "show_bottom_border": false,
        }, {
          "type": "group",
          "hide": this.hidePhoneInfo,
          "data": [
            {
              "id": 12,
              "type": "single",
              "title": this.$t('list_price'),
              "value": this.phonePriceText,
              "hide": false,
              "right_justify": true,
              "show_bottom_border": false,
            }, {
              "id": 13,
              "type": "single",
              "title": this.$t('tax'),
              "value": this.phoneTaxText,
              "hide": false,
              "right_justify": true,
              "show_bottom_border": true,
            }, {
              "id": 14,
              "type": "single",
              "title": this.$t('total'),
              "value": this.phonePriceWithTaxText,
              "hide": false,
              "right_justify": true,
              "show_bottom_border": false,
            }
          ]
        }, {
          "id": 10,
          "type": "single",
          "title": this.$t('color'),
          "value": this.form.selectedColor,
          "hide": this.hidePhoneInfo,
          "show_bottom_border": false,
        }, {
          "id": 1018,
          "type": "single",
          "title": this.$t('you_pay_upfornt'),
          "value": this.upFrontWithoutTaxText,
          "hide": this.hidePhoneInfo,
          "show_bottom_border": false,
        },
        {
          "id": 18,
          "type": "single",
          "title": this.$t('you_pay_upfornt'),
          "value": this.upFrontText,
          "hide": true,
          "show_bottom_border": false,
        }, {
          "id": 24,
          "type": "single",
          "title": this.$t('you_save'),
          "value": this.savingsText,
          "hide": this.hidePhoneInfo,
          "show_bottom_border": false,
        }, {
          "id": 25,
          "type": "single",
          "title": this.$t('you_save_monthly'),
          "value": this.savingsMonthlyText,
          "hide": this.discount >= 0,
          "show_bottom_border": false,
        }, {
          "type": "group",
//          "hide": this.isRogersCustomer(),
          "data": [
            {
              "id": 15,
              "type": "single",
              "title": this.$t('invoice_credit'),
              "value": this.invoiceCreditText,
//              "hide": this.isRogersCustomer(),
              "show_bottom_border": false,
            }, {
              "id": 16,
              "type": "single",
              "title": this.$t('setup_fee'),
              "value": this.setupFeeWithTaxText,
//              "hide": this.isRogersCustomer(),
              "show_bottom_border": true,
            }, {
              "id": 17,
              "type": "single",
              "title": this.$t('total'),
              "value": this.totalSetupFee,
//              "hide": this.isRogersCustomer(),
              "show_bottom_border": false,
            },
          ]
        }, {
          "type": "group",
          "hide": false,
          "data": [
            {
              "id": 19,
              "type": "single",
              "title": this.$t('monthly_cost'),
              "value": this.monthlyRateText,
              "hide": false,
              "right_justify": true,
              "show_bottom_border": false,
            }, {
              "id": 20,
              "type": "single",
              "title": this.$t('monthly_discount'),
              "value": this.discountText,
              "hide": this.discount >= 0,
              "right_justify": true,
              "show_bottom_border": true,
            }, {
              "id": 21,
              "type": "single",
              "title": this.$t('effective_rate'),
              "value": this.preTaxRateText,
              "hide": this.discount >= 0,
              "right_justify": true,
              "show_bottom_border": false,
            }, {
              "id": 22,
              "type": "single",
              "title": this.$t('tax'),
              "value": this.monthlyCostTaxText,
              "hide": false,
              "right_justify": true,
              "show_bottom_border": true,
            }, {
              "id": 23,
              "type": "single",
              "title": this.$t('effective_plan_rate_after_tax'),
              "value": this.monthlyCostWithTaxText,
              "right_justify": true,
              "hide": false,
              "show_bottom_border": false,
            }
          ]
        }, {
          "id": 8,
          "type": "single",
          "title": "*" + this.$t('data_plan_has'),
          "value": this.dataPlanHas,
          "hide": !this.form.selectedPlan,
          "show_bottom_border": false,
        }, {
          "id": [11, 26],
          "type": "single",
          "title": this.$t('manufacturer_link'),
          "value": this.manufacturersLink,
          "hide": this.hidePhoneInfo,
          "show_bottom_border": false,
        }, this.form.disclaimer
      ];

      this.form.newDesignData = [
        {
          "type": "group",
          "hide": this.hidePhoneInfo,
          "data": [
            {
              "id": 12,
              "type": "single",
              "title": this.$t('list_price'),
              "value": this.phonePriceText,
              "hide": false,
              "right_justify": true,
              "show_bottom_border": false,
            }, {
              "id": 13,
              "type": "single",
              "title": this.$t('tax'),
              "value": this.phoneTaxText,
              "hide": false,
              "right_justify": true,
              "show_bottom_border": true,
            }, {
              "id": 14,
              "type": "single",
              "title": this.$t('total'),
              "value": this.phonePriceWithTaxText,
              "hide": false,
              "right_justify": true,
              "show_bottom_border": false,
            },
            {
              "id": 1018,
              "type": "single",
              "title": this.$t('you_pay_upfornt'),
              "value": this.upFrontWithoutTaxText,
              "hide": this.hidePhoneInfo,
              "right_justify": true,
              "show_bottom_border": false,
            }
          ]
        },
        {
          "id": 18,
          "type": "single",
          "title": this.$t('you_pay_upfornt'),
          "value": this.upFrontText,
          "hide": true,
          "show_bottom_border": false,
        },{
          "type": "group",
          "hide": false,
          "data": [
            {
              "id": 19,
              "type": "single",
              "title": this.$t('monthly_cost'),
              "value": this.monthlyRateText,
              "hide": false,
              "right_justify": true,
              "show_bottom_border": false,
            }, {
              "id": 20,
              "type": "single",
              "title": this.$t('monthly_discount'),
              "value": this.discountText,
              "hide": this.discount >= 0,
              "right_justify": true,
              "show_bottom_border": true,
            }, {
              "id": 21,
              "type": "single",
              "title": this.$t('effective_rate'),
              "value": this.preTaxRateText,
              "hide": this.discount >= 0,
              "right_justify": true,
              "show_bottom_border": false,
            }, {
              "id": 22,
              "type": "single",
              "title": this.$t('tax'),
              "value": this.monthlyCostTaxText,
              "hide": false,
              "right_justify": true,
              "show_bottom_border": true,
            }, {
              "id": 23,
              "type": "single",
              "title": this.$t('effective_plan_rate_after_tax'),
              "value": this.monthlyCostWithTaxText,
              "right_justify": true,
              "hide": false,
              "show_bottom_border": false,
            }
          ]
        }
      ];

      this.form.computedData = {
        'plan_cost': this.planCost,
        'discount': this.discount,
        'upfront': this.formatMoney(this.upFront),
        'upfront_without_tax': this.formatMoney(this.upFrontWithoutTax),
      }
    },
    showCalculation() {
      return this.form.selectedPlan && this.form.selectedPhone && this.form.selectedProvince;
    },
    showBYODCalculation() {
      return this.form.selectedPlan && this.form.selectedProvince;
    },
    negToBracet(value) {
      if (value >= 0) {
        return this.numberToComma(Number(value).toFixed(2).toLocaleString());
      }
      let updatedValue = (value * -1);
      updatedValue = this.numberToComma(Number(value).toFixed(2).toLocaleString())
      return '(' + updatedValue + ')';
    },
    currencyFormat(text){
      if (this.$i18n.locale === 'en') {
        return "$" + text;
      }else {
        return text + " $";
      }
    },
    isRogersCustomer() {
      return this.form.rogerCustomers === this.$t('yes');
    },
    generatePrintTitle() {
      let title = "roger_" + this.form.rogerCustomers;
      if (this.form.selectedPlan) {
        title += "_" + this.form.plans[this.form.selectedPlan - 1].data;
      }
      if (this.form.selectedPhone) {
        title += "_" + this.form.phones[this.form.selectedPhone - 1].phone;
      }
      if (this.form.selectedProvince) {
        title += "_" + this.form.provinces[this.form.selectedProvince - 1].name;
      }
      let date = (new Date().toLocaleDateString());
      //replace / with _
      date = date.replace(/\//g, "_");
      title += "_" + date;
      return title;
    },
    printToPdf() {
      this.hideDisclaimer = false;
      //wait 3 seconds
      setTimeout(() => {
        var printWindow = window.open('', '', 'height=400,width=800');
        printWindow.document.write('<html><head><title>' + this.generatePrintTitle() + '</title> <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet">');
        printWindow.document.write('</head><body >');
        var divContents = document.getElementById(this.printId).innerHTML;
        printWindow.document.write(divContents);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        setTimeout(() => {
          printWindow.print();
          this.hideDisclaimer = true;
        }, 1000);
      }, 1000);
    },
    formatMoney(value) {
      return this.currencyFormat(this.negToBracet(value));
    },
    numberToComma(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
        getLastLine(form) {
      let discountValue = "0.00";
      let discount = form.computedData.discount;

      if (discount < 0) {
        discountValue = discount * -1;
      }

      if (form.selectedPhone) {
        let phone = this.form.phones[form.selectedPhone - 1];
        //1130.00-0.00-234.00-3.42 1130=listprice, 0=discount, 234=upfront, 3.42=tax
        let list = phone.rogers_list;
        list = Number(list).toFixed(2);
        let phoneUpfront = phone.upfront;
        phoneUpfront = Number(phoneUpfront).toFixed(2)


        return  list + '-' + form.computedData.upfront + '-' + phoneUpfront + '-' + discountValue + '\n';
      } else {
        return  '0.00-0.00-0.00-' + discountValue + '\n';
      }
    }
  },
  computed: {
    monthlyBundleText() {
      if (this.form.planType === this.$t('byod')){
        return "Monthly Total: ";
      }
      return "Monthly Bundle Total: ";

    },
    planCost() {
        if (this.discount < 0) {
            return {
                'title': this.$t('plan_cost'),
                'value': this.preTaxRateText + ' + ' + this.$t('tax') + ' (' + this.$t('discount_applied') + ' - ' + this.discountText + ')',
            };
        } else {
            let rateText;

            // Determine which rate to display
            if (this.isRogersCustomer()) {
                rateText = this.formatMoney(this.monthlyRate);
            } else {
                rateText = this.formatMoney(this.monthlyRate);
            }

            return {
                'title': this.$t('plan_cost'),
                'value': rateText + ' + tax',
            };
        }
    },
    planDescription() {
      if (!this.form.selectedPlan) return this.$t('no_plan_selected');

      // Use monthlyRate to display the final monthly cost for the selected plan
      return this.formatMoney(this.monthlyRate) + ' ' + this.$t('monthly') + ' + ' + this.$t('tax') + ' - ' +
             this.form.plans[this.form.selectedPlan - 1].description;
     },
    asterisksColor() {
      return {
        color: 'red',
      }
    },
    providers() {
      if (this.isRogersCustomer()) {
        return ['Rogers'];
      } else {
        return ['Bell', 'Telus', 'Freedom', 'Virgin', 'Fido', 'Chatr', 'Public', 'Shaw', 'Koodo', 'Eastlink', 'Other', 'None'];
      }
    },
    savingsMonthlyText() {
      if (this.hidePhoneInfo) {
        return this.discountText;
      }
      return this.discountText + ' '+this.$t('for')+' ' + this.form.months + ' '+this.$t('months');
    },
    shopdf() {
      let show = document.getElementById('dsae').value;
      return show !== 'p';
    },
    changeData() {
      return [this.form.selectedPlan, this.form.selectedPhone, this.form.selectedProvince, this.form.selectedColor,
        this.form.rogerCustomers, this.form.planType, this.form.months];
    },
    phoneName() {
      if (!this.form.selectedPhone) return this.$t('not_selected');
      return this.form.phones[this.form.selectedPhone - 1].phone;
    },
    phoneInfo() {
      if (!this.form.selectedPhone) return this.$t('no_phone_selected');
      let phoneData = this.form.phones[this.form.selectedPhone - 1];
      let phoneName = phoneData.phone;
      if (this.form.selectedColor) {
        phoneName += ' - ' + this.form.selectedColor;
      }
      return phoneName;
    },
    provinceName() {
      if (!this.form.selectedProvince) return this.$t('not_selected');
      return this.form.provinces[this.form.selectedProvince - 1].name;
    },
    description() {
      if (!this.form.selectedPlan) return this.$t('not_selected');
      return this.form.plans[this.form.selectedPlan - 1].description;
    },
    hidePhoneInfo() {
      return this.form.planType === this.$t('byod');
    },
    monthlyRateText() {
      if (!this.form.selectedPlan) return this.$t('not_selected');
      return this.formatMoney(this.monthlyRate);
    },
    monthlyRate() {
      if (!this.form.selectedPlan) return 'not_selected';

      const plan = this.form.plans[this.form.selectedPlan - 1];
      const byodRate = parseFloat(plan.BYOD);

      // Define minimum rates and planPaid for specific data plans
      let minimumRate = null;
      //let planPaid = 0;  // Default value for planPaid
      let phoneDisc = 0;  // Default value for phoneDisc
      // Initialize computedPhoneDisc
      let computedPhoneDisc = 0;  // Define the variable to avoid the undefined error
      let rogersRate = 0;
      // Define the phone discount based on the data plan
      if (plan.data === "10GB") {
        computedPhoneDisc = 3.75;
      } else if (plan.data === "25GB") {
        computedPhoneDisc = 2.65;
      } else if (plan.data === "50GB") {
        computedPhoneDisc = 2.25;
      } else if (plan.data === "100GB") {
        computedPhoneDisc = 2.25;
      }

      switch (plan.data) {
        case "10GB":
          minimumRate = 49;
          rogersRate = 47.50;
          phoneDisc = 3.75;
          break;
        case "25GB":
          minimumRate = 55;
          rogersRate = 57.00;
          phoneDisc = 2.65;
          break;
        case "50GB":
          minimumRate = 63.50;
          rogersRate = 67.00;
          phoneDisc = 2.25;
          break;
        case "100GB":
          minimumRate = null;  // No minimum for 100GB
          rogersRate = 67.50;
          phoneDisc = 2.25;
          break;
        default:
          minimumRate = null;  // No minimum for other plans
       //   planPaid = 0;  // No planPaid for other plans
          phoneDisc = 0;  // No phoneDisc for other plans
  }
      // Store phoneDisc value in the component data
      this.phoneDisc = phoneDisc;
      // Store the calculated phoneDisc in the form object for later use
      this.form.phoneDisc = computedPhoneDisc;

      //if rogers customer then discount is 0
      if (this.isRogersCustomer()) {
          phoneDisc = 0;
          this.phoneDisc = 0;
          this.form.phoneDisc = 0;
      }

      // Consolidated Rogers Customer Logic
      if (this.isRogersCustomer()) {
        if (this.form.selectedPhone) {
          const phone = this.form.phones[this.form.selectedPhone - 1];
          const rogersListPerMonth = phone.rogers_list / 36;
          let phoneUpfront = phone.upfront - 675 < 0 ? 0 : phone.upfront - 675;

          // Add phone costs to Rogers rate
          let totalMonthlyCost = rogersRate + rogersListPerMonth - (phoneUpfront / 36);
          return totalMonthlyCost > 0 ? totalMonthlyCost.toFixed(2) : '0.00';
        }
        return rogersRate.toFixed(2);
      }

      // If it's a BYOD plan, return just the BYOD rate
      if (this.form.planType === this.$t('byod')) {
        return byodRate.toFixed(2);  // BYOD purchase, return the BYOD rate directly
  }

      if (this.isRogersCustomer()) {
        if (this.form.selectedPhone) {
          const phone = this.form.phones[this.form.selectedPhone - 1];
          const rogersListPerMonth = phone.rogers_list / 36;
          let phoneUpfront = phone.upfront - 675 < 0 ? 0 : phone.upfront - 675;

          // Add phone cost to Rogers-specific rate
          let totalMonthlyCost = rogersRate + rogersListPerMonth - (phoneUpfront / 36);
          return totalMonthlyCost > 0 ? totalMonthlyCost.toFixed(2) : '0.00';
        }

        return rogersRate ? rogersRate.toFixed(2) : byodRate.toFixed(2);
      }

      // For phone purchases, calculate the total monthly cost
      if (this.form.selectedPhone) {
        const phone = this.form.phones[this.form.selectedPhone - 1];
        const rogersListPerMonth = phone.rogers_list / 36;
      // Subtract 675 from upfront, and if the result is < 0, set it to 0, else use the result
      let phoneUpfront = phone.upfront - 675 < 0 ? 0 : phone.upfront - 675;

      // Store the phoneUpfront value in the component data
      this.phoneUpfront = phoneUpfront;

      // Store phoneUpfront in computedData for future reference
      this.form.computedData = {
            ...this.form.computedData,  // Keep existing computed data
            phoneUpfront: phoneUpfront  // Add phoneUpfront to computedData
      };

     // Calculate total monthly cost using planPaid instead of (with_device - BYOD)
      let totalMonthlyCost = byodRate + rogersListPerMonth - phoneDisc - (phoneUpfront/36);

    // Apply the minimum rate rule only if it exists and the calculated rate is lower than the minimum
      if (minimumRate !== null && totalMonthlyCost < minimumRate) {
          totalMonthlyCost = minimumRate;
  }

    return totalMonthlyCost > 0 ? totalMonthlyCost.toFixed(2) : '0.00';
  }

  // If no phone is selected, return the BYOD rate
  return byodRate.toFixed(2);
},
    provinceTaxText() {
      if (!this.form.selectedProvince) return '13 %';
      return this.form.provinces[this.form.selectedProvince - 1].value + ' %';
    },
    dataPlan() {
      if (!this.form.selectedPlan) return this.$t('not_selected');
      return this.form.plans[this.form.selectedPlan - 1].data
    },
    plusTax() {
      if (!this.form.selectedPlan) return this.$t('not_selected');
      return this.form.plans[this.form.selectedPlan - 1].with_device
    },
    phonePriceText() {
      if (!this.form.selectedPhone) return 0;
      return this.formatMoney(this.phonePrice);
    },
    phonePrice() {
      if (!this.form.selectedPhone) return 0;
      let price = this.form.phones[this.form.selectedPhone - 1]["rogers_list"];
      return +price.toFixed(2);
    },
    provinceTax() {
      let provincePercentage = 13;
      if (this.form.selectedProvince) {
        provincePercentage = this.form.provinces[this.form.selectedProvince - 1].value;
      }
      return (provincePercentage / 100);
    },
    phoneTaxText() {
      if (!this.form.selectedPhone) return 0;
      return this.formatMoney(this.phoneTax);
    },
    phoneTax() {
      if (!this.showCalculation()) return 0;
      let tax = this.phonePrice * this.provinceTax;
      return +tax.toFixed(2);
    },
    phonePriceWithTaxText() {
      if (!this.showCalculation()) return 0;
      return this.formatMoney(this.phonePriceWithTax);
    },
    phonePriceWithTax() {
      if (!this.showCalculation()) return 0;
      let price = parseFloat(this.phonePrice) + parseFloat(this.phoneTax);
      return +price.toFixed(2);
    },
    upFrontWithoutTaxText() {
      if (!this.showCalculation()) return this.formatMoney(0) + " + " + this.$t('tax_small');
      return this.formatMoney(this.upFrontWithoutTax) + " + " + this.$t('tax_small');
    },
    upFrontText() {
      if (!this.showCalculation()) return this.formatMoney(0) + " "+ this.$t('for_the_phone');
      return this.formatMoney(this.upFront) + " "+ this.$t('for_the_phone');
    },
    upFrontCalculationWithoutTax() {
      let upfornt = this.upFront;
//      let taxRate = 0;
      let taxDevider = 1 + (0 / 100);
      let upforntWithoutTax = upfornt / taxDevider;
      return +upforntWithoutTax.toFixed(2);
    },
    upFrontCalculation() {
      if (!this.showBYODCalculation()) return 0;
      let credit = this.form.plans[this.form.selectedPlan - 1].credit;
//      if (this.isRogersCustomer()) {
//        credit = this.form.plans[this.form.selectedPlan - 1].renewal_credit;
//      }

      let upfrontWithTax = 0;

      if (this.form.selectedPhone) {
        let upFrontPrice = this.form.phones[this.form.selectedPhone - 1]['upfront'];
        upfrontWithTax = upFrontPrice;
      }
      let upfront = upfrontWithTax - (credit/1.13);
      return +upfront.toFixed(2);
    },
    upFrontWithoutTax() {
      if (!this.showCalculation()) return 0;
      let upfront = this.upFrontCalculationWithoutTax;
      if (upfront < 0) {
        return 0;
      }
      return upfront.toFixed(2);
    },
    upFront() {
      if (!this.showCalculation()) return 0;
      let upfront = this.upFrontCalculation;
      if (upfront < 0) {
        return 0;
      }
      return upfront.toFixed(2);
    },
    savingsText() {
      if (!this.showCalculation()) return 0;
      return this.formatMoney(this.savings) + " "+ this.$t('on_your_phone_purchase');
    },
    savings() {
      if (!this.showCalculation()) return 0;
      let savings = this.phonePriceWithTax - this.upFront;
      return +savings.toFixed(2);
    },
    discountText() {
      if (!this.showBYODCalculation()) return 0;
      return this.formatMoney(this.discount);
    },
    discount() {
      if (!this.showBYODCalculation()) return 0;
      //let percentage = (1.15);
      let discount = 0;
      return +discount.toFixed(2);
    },
    monthlyCost() {
      if (!this.showCalculation()) return 0;

      const phone = this.form.phones[this.form.selectedPhone - 1];
      const rogersListPerMonth = phone.rogers_list / 36;
      const phoneUpfrontPerMonth = phone.upfront / 36;

      console.log(`rogers_list per month: ${rogersListPerMonth}`); // Debugging check
      console.log(`upfront per month: ${phoneUpfrontPerMonth}`); // Debugging check
      console.log(`with_device: ${this.form.plans[this.form.selectedPlan - 1].with_device}`); // Debugging check

      const result = parseFloat(this.form.plans[this.form.selectedPlan - 1].with_device) + (rogersListPerMonth - phoneUpfrontPerMonth);

      console.log(`monthlyCost result: ${result}`); // Debugging check

      return result > 0 ? result.toFixed(2) : '0.00';
    },
    preTaxRateText() {
      if (!this.form.selectedPlan) return 0;
      return this.formatMoney(this.preTaxRate);
    },
    preTaxRate() {
      if (!this.form.selectedPlan) return 0;
      let pretax = parseFloat(this.monthlyRate) + parseFloat(this.discount);
      return +pretax.toFixed(2);
    },
    monthlyCostTaxText() {
      if (!this.form.selectedPlan) return 0;
      return this.formatMoney(this.monthlyCostTax);
    },
    monthlyCostTax() {
      if (!this.form.selectedPlan) return 0;
      let plan = this.form.plans[this.form.selectedPlan - 1];
      let planAmount = 0;
      if (this.form.planType === this.$t('byod')) {
        planAmount = parseFloat(plan.BYOD) - 10;
      } else {
        planAmount = parseFloat(plan.with_device) - 10;
      }
      let tax = planAmount * this.provinceTax + 10 * 0.13;
      return +tax.toFixed(2);
    },
    invoiceCreditText() {
      let negCurrency = this.setupFeeWithTax * -1;
      return this.formatMoney(negCurrency);
    },
    invoiceCredit() {
      return this.setupFeeWithTax * -1;
    },
    totalSetupFee() {
      return this.setupFeeWithTax + this.invoiceCredit;

    },
    setupFeeWithTaxText() {
      return this.formatMoney(this.setupFeeWithTax) + "  "+this.$t('includes_tax')+" ";
    },
    setupFeeWithTax() {
      if (this.isRogersCustomer()) {
        return 0;
      }
      let setupFeeWithTax = this.form.setupFee * 1.13;
      return +setupFeeWithTax.toFixed(2);
    },
    monthlyCostWithTaxText() {
      return this.formatMoney(this.monthlyCostWithTax) + " (" + this.$t('monthly') + ")";
    },
    monthlyCostWithTax() {
      //convert monthly cost to float
      let total = 0;
      if (this.discount > 0) {
        total = parseFloat(this.monthlyRate) + parseFloat(this.monthlyCostTax);
      } else {
        total = parseFloat(this.preTaxRate) + parseFloat(this.monthlyCostTax);
      }
      return +total.toFixed(2);
    },
    effectiveMonthlySavings() {
      if (!this.showBYODCalculation()) return 0;
      let upfront = this.upFrontCalculation;
      let monthlySavings = upfront / this.form.months;
      return +monthlySavings.toFixed(2);
    },
    dataPlanHas() {
      if (!this.form.selectedPlan) return "";
      return this.form.plans[this.form.selectedPlan - 1].description;
    },
    manufacturersLink() {
      if (!this.showCalculation()) return this.$t('no_phone_selected');
      return this.form.phones[this.form.selectedPhone - 1].manufacturers_link;
    },
    lineSummary() {
      let summary = '';
      //let lineNumber = 1;  // Initialize line number
      //plan type
            summary += this.$t('line') + " 1 - " + this.$t('plan_type_label') + ": " + this.form.planType + "\n";

      //plan description
      summary += this.$t('line_1_plan_selected') + ": " + this.form.plans[this.form.selectedPlan - 1].description + " (" +
          this.formatMoney(this.monthlyRate) + " monthly + taxes)\n"

      //phone info
      if (this.form.planType === this.$t('new_phone')) {
        const phone = this.form.phones[this.form.selectedPhone - 1];
        const rogersListPerMonth = phone.rogers_list / 36;

        if (this.phoneDisc>0) {
          summary += this.$t('line') + "1 - New Phone to Purchase: " + phone.phone + " - " + this.form.selectedColor + " ($" + this.formatMoney(phone.rogers_list) + " financed over 36 months: " + this.formatMoney(rogersListPerMonth) + " monthly + taxes).  Phone Bundle Discount: -" + this.formatMoney(this.phoneDisc) + " monthly\n";
        }else {
          summary += this.$t('line') + "1 - New Phone to Purchase: " + phone.phone + " - " + this.form.selectedColor + " ($" + this.formatMoney(phone.rogers_list) + " financed over 36 months: " + this.formatMoney(rogersListPerMonth) + " monthly + taxes)\n";
        }
      }

      //plan cost
      summary += this.$t('line') + "1 - " + ". "+this.monthlyBundleText + this.formatMoney(this.monthlyRate) + " monthly + taxes\n";

      //upfront cost
      //if is new phone
      if (this.form.planType === this.$t('new_phone')) {
        summary += this.$t('line') + "1 - " + this.$t('phone_upfront_cost') + ": " + this.formatMoney(this.phoneUpfront) + "\n";

      }

      //security deposit
      if (this.form.securityDeposit) {
        summary += this.$t('security_deposit') + ": $" + this.form.securityDeposit + "\n";
      }

      // summary += this.getLastLine(this.form);

      if (this.additionalInfo) {
        summary += "\nComments: "+ this.additionalInfo + "\n";
      }

      return summary;

    },
    lineSummaryHtml() {
      let summary = '';
//      let lineNumber = 1;  // Initialize line number
      //plan type
      summary += '<div class="formRow"><label>' + this.$t('line') + ' 1 - ' + this.$t('plan_type_label') + '</label><i>' + this.form.planType + "</i></div>";
      //plan description
      summary += '<div class="formRow"><label>' + this.$t('line') + ' 1 - New Plan Selected:</label> <i>' + this.form.plans[this.form.selectedPlan - 1].description +
          ' (' + this.formatMoney(this.monthlyRate) + ' monthly + taxes)</i></div>';
      //phone info
      if (this.form.planType === this.$t('new_phone')) {
          const phone = this.form.phones[this.form.selectedPhone - 1];
          const rogersListPerMonth = phone.rogers_list / 36;
      summary += '<div class="formRow"><label>' + this.$t('line') + ' 1 - New Phone to Purchase:</label> <i>' + phone.phone + ' - ' + this.form.selectedColor +
              ' (' + this.formatMoney(phone.rogers_list) + ' financed over 36 months: ' + this.formatMoney(rogersListPerMonth) +
            ' monthly + taxes)</i></div>'

      }

      //plan cost
      summary += '<div class="formRow"><label>' + this.$t('line') + ' 1 - ' + this.planCost['title'] + '</label> <i>' + this.planCost['value'] + "</i></div>";

      //upfront cost
      //if is new phone
      if (this.form.planType === this.$t('new_phone')) {
        summary += '<div class="formRow"><label>' + this.$t('line') + ' 1 - ' + this.$t('phone_upfront_cost') + '</label> <i>' + this.formatMoney(this.phoneUpfront) + '</i></div>';
      // Phone bundle discount

      // Monthly bundle total
        if (this.phoneDisc>0) {
          summary += '<div class="formRow"><label>' + this.$t('line') + ' 1 -  '+this.monthlyBundleText+'</label> <i>' + this.formatMoney(this.monthlyRate) + ' monthly + taxes (includes Phone Bundle Discount: - ' + this.formatMoney(this.phoneDisc) + ' monthly)</i></div>';
        }else {
            summary += '<div class="formRow"><label>' + this.$t('line') + ' 1 -  '+this.monthlyBundleText+'</label> <i>' + this.formatMoney(this.monthlyRate) + ' monthly + taxes</i></div>';
        }
      }

      //security deposit
      if (this.form.securityDeposit) {
        summary += '<div class="formRow"><label>' + this.$t('line') + ' 1 - ' + this.$t('security_deposit') + +'</label> <i> $' + this.form.securityDeposit + "</i></div>";
      }


      return summary;

    }
  },
  watch: {
    'form.rogerCustomers': function (newValue) {
      if (newValue === this.$t('yes')) {
        this.form.currentProvider = 'Rogers';
        this.form.currentProviderValue = 'Rogers';
      } else {
        this.form.currentProvider = '';
        this.form.currentProviderValue = '';
      }
    },
    changeData: {
      handler: function () {
        this.form.disclaimer = this.disclaimer;
        this.generateData();
      },
      deep: true
    },
    'form.planType': function (newValue) {
      if (newValue === this.$t('byod')) {
        this.form.selectedPhone = '';
      }
    },
    'form.currentProvider': function (newValue) {
      if (newValue === 'None') {
        this.form.securityDeposit = 300;
      } else {
        this.form.securityDeposit = 0;
      }
    },
    lineSummary: function () {
      this.resize('lineSummary');
    }
  },
  props: {
    form: Object
  }
}
