<template>
  <input required="required" type="text" checktype="c1"
         v-model="phoneNumber"
         maxlength="14"
         minlength="14"
         @input="formatPhoneNumber"
         :readonly="currentProvider === 'None'"
         pattern="^(\(\d{3}\) \d{3}-\d{4})$" fieldType=1/>
</template>

<script>

export default {
  name: 'PhoneNumber',
  data() {
    return {
      phoneNumber: this.currentMobilePhoneNumber,
    }
  },
  props: {
    currentMobilePhoneNumber: String,
    currentProvider: String
  },
  methods: {
    formatPhoneNumber() {
       // Remove any non-digit characters
      let phoneNumber = this.phoneNumber.replace(/\D/g, '');

      // Limit to 10 digits
      if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.slice(0, 10);
      }

      // Format as (XXX) XXX-XXXX
      if (phoneNumber.length <= 3) {
        this.phoneNumber = `(${phoneNumber}`;
      } else if (phoneNumber.length <= 6) {
        this.phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      } else {
        this.phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
      }
      this.$emit('update:currentMobilePhoneNumber', this.phoneNumber);
    }
  },
  watch: {
    currentProvider: function (val) {
      //if none
      if (val === 'None') {
        this.phoneNumber = this.currentMobilePhoneNumber ;
      }else {
        this.phoneNumber = '';
      }
    }
  }
}

</script>
