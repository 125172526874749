<template>
  <div>
    <div class="modal-header">
      <h2 class="modal-title">{{ title }}</h2>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <slot/>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ModalContent',
  props: {
    title: String
  },

}
</script>
