<template>
  <div class="row logo-center">
    <a href="#"><img :src="logo" class="logo"></a>
    <!--a href="#"><img src="https://www.vestanetworks.com/images/1025/img/savemobility2.png" class="logo"></a-->
  </div>
</template>

<script>
export default {
  computed: {
    logo() {
      return document.getElementById('org').value;
    },
  }
}
</script>
