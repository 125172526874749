import Vue from 'vue'
import SignUpApp from './SignupApp.vue'
import MoreApp from './MoreApp.vue';
import i18n from './i18n'
import axios from "axios";

Vue.config.productionTip = false
let language = document.getElementById('language').value;
if (!language) {
    language = 'en';
}
i18n.locale = language;

let form = i18n.t("form");

let phoneUrl = 'https://formdata.vestarogers.com/' + i18n.locale + '/phones.json';

axios.get(phoneUrl).then(res => {
    let phones = res.data;
    loadApps(phones);
}).catch(() => {

    loadApps(null);
})

function loadApps(phones) {
    new Vue({
        el: '#signupapp',

        data: {
            form: form
        },

        i18n,
        created() {
            if (phones) {
                this.form.phones = phones;
            }
        },
        methods: {
            updatePhoneData: function () {
                phoneUrl = 'https://formdata.vestarogers.com/' + i18n.locale + '/phones.json';
                axios.get(phoneUrl).then(res => {
                    this.form.phones = res.data;
                })
            }
        },

        render(createElement) {
            return createElement(SignUpApp, {
                props: {
                    form: this.form
                }
            })
        },
        watch: {
            '$i18n.locale': {
                handler() {
                    this.form = this.$t("form")
                    this.updatePhoneData();
                },
            }
        }
    });
    new Vue({
        el: '#moreapp',
        data: {
            form: form
        },
        methods: {
            updatePhoneData: function () {
                phoneUrl = 'https://formdata.vestarogers.com/' + i18n.locale + '/phones.json';
                axios.get(phoneUrl).then(res => {
                    this.form.phones = res.data;
                })
            }
        },
        created() {
            if (phones) {
                this.form.phones = phones;
            }
        },
        i18n,
        render(createElement) {
            return createElement(MoreApp, {
                props: {
                    form: this.form
                }
            })
        },
        watch: {
            '$i18n.locale': {
                handler() {
                    this.form = this.$t("form")
                    this.updatePhoneData();
                },
            }
        }
    })
}

