<template>
  <ModalContent :title="$t('complete_the_form')">
    <BeforeSignUpForm :form="form" v-if="currentStep===1" @nextStep="currentStep++"/>
    <Form :form="form" v-if="currentStep===2" @nextStep="currentStep++" @previousStep="--currentStep"/>
    <SignUpForm :form="form" v-if="currentStep===3" @previousStep="--currentStep"/>
  </ModalContent>
</template>

<script>
import Form from './components/Form.vue'
import SignUpForm from "@/components/SignUpForm";
import BeforeSignUpForm from "@/components/BeforeSignUp.vue";
import ModalContent from "@/components/ModalContent.vue";

export default {
  name: 'SignupApp',
  created() {
    //on close the signupapp bootstrap modal, reset the form
    //get the modal
    const modal = document.getElementById('signup');
    //on close the modal, reset the form
    modal.addEventListener('hidden.bs.modal', () => {
      this.currentStep = 1;
    });

  },
  data() {
    return {
      currentStep: 1,
    };
  },
  props: {
    form: Object
  },
  components: {
    ModalContent,
    BeforeSignUpForm,
    Form, SignUpForm
  },
}
</script>

<style>
#app {

}
</style>
