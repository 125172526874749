<template>
  <Modal @close="$emit('close')">
    <template #header>
      <h2 class="modal-title">{{$t('interaction_id_help')}}</h2>
    </template>
    <template #body>
      <div id="interactionmodal">
        <p v-html="$t('interaction_part_1')"></p>

        <p> {{$t('interaction_part_2')}}</p>

        <p> {{$t('interaction_part_3')}}</p>
        <p> {{$t('interaction_part_4')}}</p>


      </div>
    </template>
    <template #footer>
      <a href="#" class="copybtn" @click="copyText">{{$t('copy')}}</a>
    </template>
  </Modal>
</template>


<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {Modal},
  methods: {
    async copyText() {
      //get text from html id
      const text = document.getElementById('interactionmodal').innerText;

      try {
        await navigator.clipboard.writeText(text);
        alert('Instruction copied to clipboard');
      } catch ($e) {
        alert('Cannot copy');
      }
    },
  }
}
</script>
